import * as React from "react";
import { Box, Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Landing from "./components/Landing/Landing";
import PageTitle from "./components/PageTitle";
import getLPTheme from "./getLPTheme";
import Header from "./components/Header/Header";
import styles from "./Main.module.scss";
import Footer from "./components/Footer/Footer";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import Profile from "./components/Profile/Profile";
import WorldMap from "./components/WorldMap/WorldMap";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ScrollToTop from "./components/ScrollToTop";

const zombieTrain = "ZombieTrain";
export default function App() {
  const LPtheme = createTheme(getLPTheme("dark"));

  return (
    <Provider store={store}>
      <ThemeProvider theme={LPtheme}>
        <CssBaseline />
        <Box>
          <Router>
            <Header />
            <ScrollToTop />
            <Box className={styles.pageBox}>
              <Container className={styles.pageContainer}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <>
                        <PageTitle title={zombieTrain + " - Home"} />
                        <Landing />
                      </>
                    }
                  />
                  <Route
                    path="/world-map"
                    element={
                      <>
                        <PageTitle title={zombieTrain + " - World Map"} />
                        <WorldMap interactive={true} />
                      </>
                    }
                  />
                  <Route
                    path="/leaderboard"
                    element={
                      <>
                        <PageTitle title={zombieTrain + " - Leaderboard"} />
                        <Leaderboard />
                      </>
                    }
                  />
                  <Route
                    path="/profile/:userId"
                    element={
                      <>
                        <PageTitle title={zombieTrain + " - Profile"} />
                        <Profile />
                      </>
                    }
                  />
                </Routes>
                <Footer />
              </Container>
            </Box>
          </Router>
        </Box>
      </ThemeProvider>
    </Provider>
  );
}
