import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./Leaderboard.module.scss";
import LeaderboardSkeleton from "./LeaderboardSkeleton";
import { fetchLeadearboard, RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";

function Leaderboard() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rows, total, loadingStatus, error } = useSelector(
    (state: RootState) => state.leaderboard
  );

  useEffect(() => {
    dispatch(fetchLeadearboard({ page, rowsPerPage }));
  }, [page, rowsPerPage]);

  const handleChangePage = (_: React.MouseEvent | null, page: number) =>
    setPage(page);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (userId: number) => () => {
    navigate("/profile/" + userId);
  };

  if (error) return <div>Error: {error.message}</div>;

  return (
    <TableContainer
      id="leaderboard"
      component={Paper}
      className={styles.tableContainer}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell align="left">Nickname</TableCell>
            <TableCell align="right">Total kills</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingStatus === "loading" && (
            <LeaderboardSkeleton count={rowsPerPage} />
          )}
          {loadingStatus === "idle" &&
            rows.map((row) => (
              <TableRow
                key={row.user_id}
                onClick={handleRowClick(row.user_id)}
                style={{ cursor: "pointer" }}
              >
                <TableCell component="th" scope="row">
                  {row.position}
                </TableCell>
                <TableCell align="left">{row.user_name}</TableCell>
                <TableCell align="right">{row.total_score}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default Leaderboard;
