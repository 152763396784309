import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../images/logo.png";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import Links from "./Links";

function Header() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const goToSocialMedia = () => {
    const sectionElement = document.getElementById("sm-links");
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  return (
    <AppBar className={styles.wrapper}>
      <Container className={styles.container}>
        <Toolbar className={styles.toolbar}>
          <Box className={styles.left}>
            <Link to="/">
              <img src={logo} className={styles.logo} alt="ZombieTrain" />
            </Link>
            <Box className={styles.links}>
              <Links goToSocialMedia={goToSocialMedia} />
            </Box>
          </Box>

          <Button
            color="secondary"
            variant="contained"
            className={styles.signInButton}
          >
            Sign in
          </Button>

          <Box className={styles.drawerWrapper}>
            <Button onClick={toggleDrawer(true)}>
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box className={styles.drawer}>
                <div className={styles.drawerLinks}>
                  <Links goToSocialMedia={goToSocialMedia} />
                </div>
                <MenuItem>
                  <Button
                    color="secondary"
                    variant="contained"
                    className={styles.drawerSignIn}
                  >
                    Sign in
                  </Button>
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
