import { MenuItem, Typography } from "@mui/material";
import styles from "./Header.module.scss";
import { Link } from "react-router-dom";

interface LinksParams {
  goToSocialMedia: () => void;
}

function Links({ goToSocialMedia }: LinksParams) {
  return (
    <>
      <MenuItem component="button" className={styles.link}>
        <Link className={styles.routeLink} to="/world-map">
          <Typography variant="body2">World Map</Typography>
        </Link>
      </MenuItem>
      <MenuItem component="button" className={styles.link}>
        <Link className={styles.routeLink} to="/leaderboard">
          <Typography variant="body2">Leaderboard</Typography>
        </Link>
      </MenuItem>
      <MenuItem
        component="button"
        className={styles.link}
        onClick={goToSocialMedia}
      >
        <Typography variant="body2">Social Media</Typography>
      </MenuItem>
    </>
  );
}

export default Links;
