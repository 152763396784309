import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createApiCallState } from "../createApiCallState";

interface Row {
  position: number;
  user_id: number;
  user_name: string;
  total_score: number;
}

interface LeaderBoardData {
  total: number;
  data: Row[];
}

const initialState = createApiCallState<{ rows: Row[]; total: number }>({
  rows: [],
  total: 0,
});

export const fetchLeadearboard = createAsyncThunk(
  "/fetchLeaderboard",
  async ({ page, rowsPerPage }: { page: number; rowsPerPage: number }) => {
    const response = await fetch(
      "/api/leaderboard?" +
        new URLSearchParams({
          offset: (page * rowsPerPage).toString(),
          limit: rowsPerPage.toString(),
        })
    );
    return (await response.json()) as LeaderBoardData;
  }
);

export const leaderboardSlice = createSlice({
  name: "leaderboard",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadearboard.pending, (state) => {
        state.loadingStatus = "loading";
        state.error = null;
      })
      .addCase(fetchLeadearboard.fulfilled, (state, action) => {
        state.total = action.payload.total;
        state.rows = action.payload.data;
        state.loadingStatus = "idle";
        state.error = null;
      })
      .addCase(fetchLeadearboard.rejected, (state, action) => {
        state.loadingStatus = "failed";
        state.error = action.error;
      });
  },
});
