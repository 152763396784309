import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createApiCallState } from "../createApiCallState";

interface WorldMapData {
  region: string;
  zombies_left: number;
  infestation_level: "high" | "medium" | "low";
}

const initialState = createApiCallState<{ worldMap: WorldMapData[] }>({
  worldMap: [],
});

export const fetchWorldMap = createAsyncThunk("/fetchProfileById", async () => {
  const response = await fetch(`/api/world-map/`);
  return (await response.json()) as WorldMapData[];
});

export const worldMapSlice = createSlice({
  name: "worldMap",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorldMap.pending, (state) => {
        state.loadingStatus = "loading";
        state.error = null;
      })
      .addCase(fetchWorldMap.fulfilled, (state, action) => {
        state.worldMap = action.payload;
        state.loadingStatus = "idle";
        state.error = null;
      })
      .addCase(fetchWorldMap.rejected, (state, action) => {
        state.loadingStatus = "failed";
        state.error = action.error;
      });
  },
});
