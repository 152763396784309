import * as React from "react";
import Hero from "../Hero";
import Leaderboard from "../Leaderboard/Leaderboard";
import { Link } from "react-router-dom";
import styles from "./Landing.module.scss";
import WorldMap from "../WorldMap/WorldMap";
import RegionBoard from "../RegionBoard/RegionBoard";
import corner from "../../images/corner.png";
import flag from "../../images/red-flag.png";

export default function Landing() {
  return (
    <>
      <div className={styles.block}>
        <Hero />
      </div>

      <Link to="/world-map" className={styles.link}>
        <div className={styles.block}>
          <div className={styles.flag}>
            <img src={flag} className={styles.flagImg} />
            <div className={styles.flagText}>map</div>
          </div>
          <img src={corner} className={styles.corner} />
          <img src={corner} className={styles.corner} />
          <img src={corner} className={styles.corner} />
          <img src={corner} className={styles.corner} />
          <WorldMap interactive={true} />
        </div>
      </Link>

      <div className={styles.block}>
        <RegionBoard />
      </div>

      <Link to="/leaderboard" className={styles.link}>
        <Leaderboard />
      </Link>
    </>
  );
}
