import { Chip, Paper } from "@mui/material";
import { useEffect } from "react";
import styles from "./Profile.module.scss";
import Avatar from "./Avatar";
import { useParams } from "react-router-dom";
import { RootState, useAppDispatch, fetchProfileById } from "../../store";
import { useSelector } from "react-redux";

export default function Profile() {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const { profile, loadingStatus, error } = useSelector(
    (state: RootState) => state.profile
  );

  useEffect(() => {
    userId && dispatch(fetchProfileById(userId));
  }, [userId]);

  if (loadingStatus === "loading") return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!profile) return <div>There is no such profile</div>;

  return (
    <div className={styles.wrapper}>
      <Paper className={styles.mainInfo}>
        <div className={styles.mainInfoAvatar}>
          <Avatar />
        </div>
        <div className={styles.userName}>
          <div>{profile.username}</div>
          <div>
            {profile.is_banned && <Chip label="Banned" />}
            {profile.is_cheater && <Chip label="Cheater" />}
          </div>
        </div>
        <div className={styles.rank}>
          <div>RANK</div>
          <div className={styles.rankText}>{profile.id}</div>
        </div>
      </Paper>
      <Paper className={styles.stats}>
        <div className={styles.statsLine}>
          <div className={styles.statsBlock}>User id</div>
          <div className={styles.statsBlock}>{profile.id}</div>
        </div>
        <div className={styles.statsLine}>
          <div className={styles.statsBlock}>Register date</div>
          <div className={styles.statsBlock}>{profile.date_joined}</div>
        </div>
        <div className={styles.statsLine}>
          <div className={styles.statsBlock}>User region</div>
          <div className={styles.statsBlock}>{profile.current_region_name}</div>
        </div>
      </Paper>
    </div>
  );
}
