import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import styles from "./RegionBoard.module.scss";
import RegionBoardSkeleton from "./RegionBoardSkeleton";
import { fetchRegionBoard, RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";

function RegionBoard() {
  const dispatch = useAppDispatch();
  const { rows, loadingStatus, error } = useSelector(
    (state: RootState) => state.regionBoard
  );

  useEffect(() => {
    dispatch(fetchRegionBoard());
  }, []);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <TableContainer
      id="regionboard"
      component={Paper}
      className={styles.tableContainer}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>Zombies</TableCell>
            <TableCell>Players</TableCell>
            <TableCell>Infection</TableCell>
            <TableCell>Option</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingStatus === "loading" && <RegionBoardSkeleton />}
          {loadingStatus === "idle" &&
            rows.map((row) => (
              <TableRow key={row.region}>
                <TableCell>{row.region}</TableCell>
                <TableCell>{row.zombies}</TableCell>
                <TableCell>{row.players}</TableCell>
                <TableCell>{row.infection}</TableCell>
                <TableCell>{row.option}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default RegionBoard;
