import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createApiCallState } from "../createApiCallState";
import { formatDate } from "../../utils";

interface Profile {
  id: number;
  username: string;
  current_region_id: number;
  current_region_name: string;
  current_region_score_value: number;
  is_banned: boolean;
  is_cheater: boolean;
  is_suspicious: boolean;
  date_joined: string;
}

const initialState = createApiCallState<{ profile: Profile | null }>({
  profile: null,
});

export const fetchProfileById = createAsyncThunk(
  "/fetchProfileById",
  async (userId: string) => {
    const response = await fetch(`/api/users/${userId}`);
    return (await response.json()) as Profile;
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileById.pending, (state) => {
        state.loadingStatus = "loading";
        state.error = null;
      })
      .addCase(fetchProfileById.fulfilled, (state, action) => {
        state.profile = {
          ...action.payload,
          date_joined: formatDate(new Date(action.payload.date_joined)),
        };
        state.loadingStatus = "idle";
        state.error = null;
      })
      .addCase(fetchProfileById.rejected, (state, action) => {
        state.loadingStatus = "failed";
        state.error = action.error;
      });
  },
});
