import { Box, Link, Typography } from "@mui/material";
import { useMemo } from "react";
import styles from "./Footer.module.scss";

export default function Copyright() {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <Box className={styles.copyright}>
      <Typography variant="body2" className={styles.text}>
        Copyright ©&nbsp;
        <Link className={styles.text} href="https://zombietra.in/">
          ZombieTrain
        </Link>
        &nbsp; {currentYear} - All rights reserved.
      </Typography>
    </Box>
  );
}
