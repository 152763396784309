import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import DiscordIcon from "./DiscordIcon";

export const socialMediaLinks = [
  {
    icon: DiscordIcon,
    href: "https://discord.gg/hMajvqD9n4",
    title: "Discord",
  },
  {
    icon: TwitterIcon,
    href: "https://x.com/zombietraingame",
    title: "Twitter",
  },
  {
    icon: InstagramIcon,
    href: "https://www.instagram.com/zombie.train/",
    title: "Instagram",
  },
  {
    icon: YouTubeIcon,
    href: "https://www.youtube.com/@ZOMBIETRAIN-vg5mo",
    title: "YouTube",
  },
  {
    icon: FacebookIcon,
    href: "https://www.facebook.com/profile.php?id=61558972854377",
    title: "Facebook",
  },
];

export const companyLinks = [
  {
    href: "#",
    title: "About us",
  },
  {
    href: "#",
    title: "Privacy",
  },
  {
    href: "#",
    title: "Terms",
  },
];
