import { SerializedError } from "@reduxjs/toolkit";

export const createApiCallState = <T extends Object>(
  state: T
): {
  loadingStatus: "loading" | "idle" | "failed";
  error: SerializedError | null;
} & T => {
  return { ...state, loadingStatus: "idle", error: null };
};
