import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createApiCallState } from "../createApiCallState";

interface RegionBoardData {
  region: string;
  zombies: number;
  players: number;
  infection: number;
  option: string;
}

const mockData: RegionBoardData[] = [
  {
    region: "Africa",
    zombies: 1000,
    players: 1000,
    infection: 60,
    option: "status",
  },
  {
    region: "Europe",
    zombies: 1000,
    players: 1000,
    infection: 60,
    option: "status",
  },
  {
    region: "Asia",
    zombies: 1000,
    players: 1000,
    infection: 60,
    option: "status",
  },
];

const initialState = createApiCallState<{ rows: RegionBoardData[] }>({
  rows: [],
});

const fetchRegionBoardData = new Promise<RegionBoardData[]>((r) =>
  setTimeout(() => {
    r(mockData);
  }, 500)
);

export const fetchRegionBoard = createAsyncThunk(
  "/fetchRegionBoard",
  async () => {
    return await fetchRegionBoardData;
  }
);

export const regionBoardSlice = createSlice({
  name: "regionBoard",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegionBoard.pending, (state) => {
        state.loadingStatus = "loading";
        state.error = null;
      })
      .addCase(fetchRegionBoard.fulfilled, (state, action) => {
        state.rows = action.payload;
        state.loadingStatus = "idle";
        state.error = null;
      })
      .addCase(fetchRegionBoard.rejected, (state, action) => {
        state.loadingStatus = "failed";
        state.error = action.error;
      });
  },
});
