import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import Copyright from "./Copyright";
import styles from "./Footer.module.scss";
import { companyLinks, socialMediaLinks } from "./links";

export default function Footer() {
  return (
    <Container className={styles.container}>
      <Box className={styles.row}>
        <Box className={styles.column}>
          <Typography variant="h6" className={styles.header}>
            Company
          </Typography>
          {companyLinks.map((link) => (
            <Link key={link.title} className={styles.link} href={link.href}>
              <InfoIcon className={styles.icon} />
              {link.title}
            </Link>
          ))}
        </Box>
        <Box className={styles.column}>
          <Typography variant="h6" className={styles.header} id="sm-links">
            Social Media
          </Typography>
          {socialMediaLinks.map((link) => (
            <Link key={link.title} className={styles.link} href={link.href}>
              <link.icon className={styles.icon} />
              {link.title}
            </Link>
          ))}
        </Box>
      </Box>

      <Copyright />
    </Container>
  );
}
