import { Skeleton, TableCell, TableRow } from "@mui/material";

export default function RegionBoardSkeleton({}) {
  return (
    <>
      {Array(4)
        .fill(1)
        .map((_, i) => (
          <TableRow key={i} style={{ cursor: "pointer" }}>
            <TableCell>
              <Skeleton variant="rectangular" height={20} />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" height={20} />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" height={20} />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" height={20} />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" height={20} />
            </TableCell>
          </TableRow>
        ))}
    </>
  );
}
