import { Skeleton, TableCell, TableRow } from "@mui/material";

type LeaderboardSkeletonProps = {
  count: number;
};

export default function LeaderboardSkeleton({
  count,
}: LeaderboardSkeletonProps) {
  return (
    <>
      {Array(count)
        .fill(1)
        .map((_, i) => (
          <TableRow key={i} style={{ cursor: "pointer" }}>
            <TableCell component="th" scope="row">
              <Skeleton variant="rectangular" height={20} />
            </TableCell>
            <TableCell component="th" scope="row">
              <Skeleton variant="rectangular" height={20} />
            </TableCell>
            <TableCell component="th" scope="row">
              <Skeleton variant="rectangular" height={20} />
            </TableCell>
          </TableRow>
        ))}
    </>
  );
}
