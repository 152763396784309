import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { useDispatch } from "react-redux";
import {
  leaderboardSlice,
  profileSlice,
  regionBoardSlice,
  worldMapSlice,
} from "./slices";

export const store = configureStore({
  reducer: {
    profile: profileSlice.reducer,
    leaderboard: leaderboardSlice.reducer,
    worldMap: worldMapSlice.reducer,
    regionBoard: regionBoardSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
