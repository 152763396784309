import { ReactSVG } from "react-svg";
import worldMapImage from "../../images/world-map.svg";
import { useEffect } from "react";
import styles from "./WorldMap.module.scss";
import { Paper } from "@mui/material";
import { fetchWorldMap, RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";

const infectionColorMap: { [key: string]: string } = {
  high: "darkred",
  medium: "#9B870C",
  low: "darkgreen",
};

export default function WorldMap({
  interactive = false,
}: {
  interactive: boolean;
}) {
  const dispatch = useAppDispatch();
  const { worldMap, loadingStatus, error } = useSelector(
    (state: RootState) => state.worldMap
  );

  useEffect(() => {
    dispatch(fetchWorldMap());
  }, []);

  if (loadingStatus === "loading") return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <Paper className={styles.wrapper}>
      <ReactSVG
        afterInjection={(svg) => {
          worldMap.forEach(({ region, zombies_left, infestation_level }) => {
            const region_id = region.toLowerCase().replace(/\s/g, "-");
            const regionElement = svg.getElementById(region_id) as SVGElement;
            const buttonElement = svg.getElementById(
              "btn-" + region_id
            ) as SVGPathElement;
            const scoreElement = svg.getElementById(
              "txt-" + region_id
            ) as SVGTextPathElement;

            if (regionElement && buttonElement && scoreElement) {
              regionElement.style.fill = infectionColorMap[infestation_level];

              scoreElement.textContent = zombies_left.toString();
              scoreElement.style.fill = "white";
              if (interactive) {
                [regionElement, buttonElement, scoreElement].forEach(
                  (element) => {
                    element.style.cursor = "pointer"; // Change cursor to pointer on hover
                    element.style.stroke = "none"; // No border initially
                    regionElement.style.transition =
                      "fill 0.3s ease, stroke 0.3s ease, filter 0.3s ease";
                    // Add hover effects using CSS
                    element.addEventListener("mouseenter", () => {
                      regionElement.style.filter = "brightness(150%)"; // Make 50% lighter
                      regionElement.style.stroke = "black"; // Add black border
                      regionElement.style.strokeWidth = "2"; // Set border width
                    });
                    element.addEventListener("mouseleave", () => {
                      regionElement.style.filter = "none"; // Remove filter effect
                      regionElement.style.stroke = "none"; // Remove border
                    });
                  }
                );
              }
            }
          });
        }}
        src={worldMapImage}
      />
    </Paper>
  );
}
