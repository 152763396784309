import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import IntroVideo from "./IntroVideo";
import appStoreImage from "../images/app-store.png";
import googlePlayImage from "../images/google-play.png";
import telegramPlayImage from "../images/telegram-play.png";

const images = [
  {
    url: appStoreImage,
    href: "https://apps.apple.com/pl/app/zombietrain-choochoo/id6479581262",
    title: "App Store",
  },
  {
    url: telegramPlayImage,
    href: "https://t.me/zombietrainbot",
    title: "Telegram",
  },
  {
    url: googlePlayImage,
    href: "https://play.google.com/store/apps/details?id=com.KorubovGames.ZombieTrain",
    title: "Google Play",
  },
];

const logoStyle = {
  maxWidth: "200px",
  width: "100%",
  outline: { xs: "0.5px solid white", sm: "1px solid white" },
  borderRadius: "5pt",
};

const gridStyle = {
  marginTop: "1%",
  marginRight: "7%",
  marginLeft: "7%",
};

export default function Hero() {
  return (
    <div>
      <IntroVideo />
      <Container
        id="marketplaces"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          mt: { xs: 2, sm: 3 },
        }}
      >
        {images.map((image) => (
          <Grid item key={image.title} style={gridStyle}>
            <a href={image.href} target="_blank" rel="noreferrer">
              <Box
                component="img"
                src={image.url}
                sx={logoStyle}
                alt="ZombieTrain"
              />
            </a>
          </Grid>
        ))}
      </Container>
    </div>
  );
}
